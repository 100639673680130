<template>
  <div>
    <el-form label-position="right">
      <title-and-description :field="field" />
      <el-row type="flex" :gutter="30">
        <el-col :span="12">
          <placeholder :field="field" />
        </el-col>
        <el-col :span="12">
          <field-filled-by :field="field" />
        </el-col>
      </el-row>
      <!-- <el-row :gutter="50">
        <el-col :span="12">
          <div class="form-group">
            <el-form-item label="Options">
              <el-select
                placeholder="Enter options"
                v-model="field.options"
                :clearable="true"
                :allow-create="true"
                collapse-tags
                :default-first-option="true"
                :filterable="true"
                :multiple="true"
                :no-data-text="''"
              />
            </el-form-item>
          </div>
        </el-col>
      </el-row> -->
      <el-row :gutter="30">
          <el-col :span="12">
            <div class="form-group">
              <div class="mb-05">Enter options separated by ','<span style="color:red; font-size: 20px; margin-left:10px;">* </span> </div>
              <el-input
            
                type="textarea"
                class="input-new-tag ml-0"
                v-model="inputValue"
                :rows="3"
                ref="saveTagInput"
                @keyup.enter.native="handleInputConfirm"
                @blur="handleInputConfirm"
              ></el-input>
            </div>
            <el-button
              type="primary"
              class="button-new-tag mt-1"
              size="mini"
              @click="showInput"
              >+ Add Options</el-button
            >
          </el-col>
          <el-col :span="12">
            <div class="mb-05">Options</div>
            <el-card class="el-card-10">
              <p v-if="!field.options.length">No Option Added Yet!</p>
                <el-scrollbar>
              <span v-for="(option, index) in field.options" :key="index">
                <el-tag
                  closable
                  class="mb-05 mr-05"
                  :disable-transitions="false"
                  @close="handleClose(option)"
                  >{{ option }}</el-tag
                >
              </span>
            </el-scrollbar>
            </el-card>
          </el-col>
        </el-row>

      <!-- <is-field-required :field="field" /> -->
    </el-form>
  </div>
</template>

<script>
import TitleAndDescription from "./TitleAndDescription";
// import IsFieldRequired from "./IsFieldRequired";
// import FieldFilledBy from "./FieldFilledBy";
// import Placeholder from "./Placeholder";

export default {
  name: "MultiSelect",
  components: {
    TitleAndDescription,
    // IsFieldRequired,
    // FieldFilledBy,
    // Placeholder
  },
  props: ["field"],
  data() {
    return {
      validations: [],
      rowsOptions: [
        {
          title: "Folder 1",
          _id: "1"
        },
        {
          title: "Folder 2",
          _id: "2"
        },
        {
          title: "Folder 3",
          _id: "3"
        }
      ],
      options: [],
      inputVisible: false,
      inputValue: "",
    };
  },
  methods: {
    handleInputConfirm() {
      let inputValue = this.inputValue.trim();
      let inpuValues = inputValue.split(",");

      if (inpuValues.length) {
        inpuValues.forEach((value) => {
          value = value.trim();
          if (value.length) {
            let existed = this.field.options.find((value1) => value1 == value);
            if (!existed) {
              this.field.options.push(value);
            }
          }
        });
      }
      this.inputVisible = false;
      this.inputValue = "";
    },
    showInput() {
      this.inputVisible = true;
      this.$nextTick(() => {
        this.$refs.saveTagInput.focus();
      });
    },
    handleClose(tagValue) {
      let accessionIdIndex = this.field.options.findIndex((x) => x == tagValue);
      this.field.options.splice(accessionIdIndex, 1);
    },
  },
};
</script>

<style lang="scss">
</style>