<template>
    <div>
      <el-form label-position="right">
        <title-and-description :field="field" />
        <el-row type="flex" :gutter="30">
          <el-col :span="12">
            <div class="form-group">
              <el-form-item label="Field Options">
                <span style="color:red; font-size: 20px;">* </span>
                <el-select
                  placeholder="Enter options"
                  v-model="field.options"
                  :clearable="true"
                  :allow-create="true"
                  :default-first-option="true"
                  :filterable="true"
                  :multiple="true"
                  :no-data-text="''"
                >
                  <el-option
                    v-for="(opt, index) of field.options"
                    :key="index"
                    :label="opt"
                    :value="opt"
                  >
                  </el-option>
                </el-select>
                <i
                  class="el-icon-edit"
                  @click="openOptions"
                  style="margin-left: 5px"
                ></i>
              </el-form-item>
            </div>
          </el-col>
        </el-row>
      </el-form>
      <dialog-component
      :visible="optionsDialogVisible"
      :title="'Edit options'"
      @before-close="optionsDialogVisibleClose"
      :containerWidth="'35%'"
      :containerHeight="'55%'"
        center>
      <div class="tableScroll">
        <el-table  
         border
        :data="dialogOptions"
        @cell-mouse-enter="editoptionsData" 
        @cell-mouse-leave="resetoptionsData" 
        >
        <el-table-column  label="Options">
          <template slot-scope="scope">
            <div v-if="currentActiveRowIndex == scope.$index">
              <el-input size="mini" v-model="dialogOptions[currentActiveRowIndex]"></el-input>
            </div>
            <div v-else>
              <p style="font-size: 14px" slot="reference">
              {{ scope.row }}
            </p>  
          </div>
          </template>  
        </el-table-column>
        </el-table>
      </div>
        <el-row>
           <el-button style="background-color: #409eff;float:right; color: #ffffff" class="type-2" 
            @click="saveData">Save</el-button>
        </el-row>
      </dialog-component>
      </div>
  </template>
  
  <script>
  import { mapGetters } from "vuex";
  export default {
    name: "MultiSelect",
    components: {
      TitleAndDescription: () => import("./TitleAndDescription"),
    },
    props: ["field"],
    data() {
      return {
        currentActiveRowIndex: -1,
        optionsDialogVisible: false,
        newOption: "",
        validations: [],
        rowsOptions: [
          {
            title: "Folder 1",
            _id: "1",
          },
          {
            title: "Folder 2",
            _id: "2",
          },
          {
            title: "Folder 3",
            _id: "3",
          },
        ],
        dialogOptions: [],
      };
    },
    computed: {
      ...mapGetters("globalVariables", ["getAllGlobalVariables"]),
      allGlobalVariables() {
        return this.getAllGlobalVariables
          ? this.getAllGlobalVariables.data || []
          : [];
      },
    },
    methods: {
      optionsDialogVisibleClose(){
        this.optionsDialogVisible=false;
      },
      openOptions() {
        if (this.isView) {
          return;
        }
        this.optionsDialogVisible = true;
      },
      changeField() {
        this.$set(this.form, this.field.key, !this.form[this.field.key]);
      },
      editoptionsData(row) {
        this.currentActiveRowIndex = this.field.options.indexOf(row);
      },
      resetoptionsData() {
        const uniqueOptions = [...new Set(this.dialogOptions)];
        if (uniqueOptions.length < this.dialogOptions.length) {
          this.dialogOptions = uniqueOptions;
        }
        this.field.options = this.dialogOptions.slice();
        this.currentActiveRowIndex = -1;
      },
      saveData(){
        this.optionsDialogVisible = false;
      },
      addOption() {
        if (this.newOption.trim() !== "") {
          this.field.options.push(this.newOption.trim());
          this.newOption = "";
        }
      },
      deleteOption(index) {
        // Delete option logic here
        this.field.options.splice(index, 1);
      },
      async fetchGlobalVaribales() {
        let params = {
          get_all: true,
          input_type: this.field.input_type,
        };
  
        await this.$store.dispatch(
          "globalVariables/fetchGlobalVariables",
          params
        );
      },
    },
    watch: {
      optionsDialogVisible(newVal) {
        if (newVal) {
          this.dialogOptions = this.field.options.slice();
        }
      },
    },
  };
  </script>
  
  <style lang="scss">
  .el-select .el-select__tags {
    display: flex;
    overflow-x: hidden;
    flex-wrap: nowrap;
  }
  </style>
  