<template>
  <div class="form-group mt-1">
    <label>Select Tag</label>
    <div class="d-gird-auto-100 mt-05">
      <el-select
        v-model="field.tags"
        multiple
        placeholder="Select Tag"
        filterable
      >
        <el-option
          v-for="(item, index) in this.getAllGlobalVariableTags.data"
          :key="index"
          :label="item.name"
          :value="item._id"
        ></el-option>
      </el-select>
      <el-popover v-model="addTagVisible" placement="right-end">
        <AddTag
          v-if="addTagVisible"
          v-on:closed="closeAddTag"
          v-on:tag-added="setNewTag"
        ></AddTag>
        <el-button
          slot="reference"
          class="add-field-btn"
          size="mini"
          type="primary"
        >
          <div class="icon-block d-flex align-center">
            <div class="text">New</div>
            <div class="icon ml-1">
              <img
                alt="icon"
                src="@/assets/img/icons/plus-icon.svg"
                width="10"
              />
            </div>
          </div>
        </el-button>
      </el-popover>
    </div>
    <FormError error-key="tag" />
  </div>
</template>

<script>
import FormError from "@/components/form/FormError";
import { mapGetters } from "vuex";
import AddTag from "./AddTag";

export default {
  /**
   * Components properties.
   *
   * @type {Object}
   */
  props: ["field"],

  /**
   * Components child components.
   *
   * @type {Object}
   */
  components: {
    FormError,
    AddTag,
  },

  /**
   * Components initial properties.
   *
   * @type {Object}
   */
  data() {
    return {
      addTagVisible: false,
    };
  },

  /**
   * Components computed properties.
   *
   * @type {Object}
   */
  computed: {
    logoUploadUrl() {
      return process.env.VUE_APP_S3_BUCKET_URL;
    },
    ...mapGetters("globalVariables", [
      "getAllGlobalVariableTags",
      "getGlobalVariableErrors",
    ]),
  },

  /**
   * Components mounted life hook.
   *
   * @type {Object}
   */
  async mounted() {
    await this.fetchAllGlobalVariableTags();
  },

  /**
   * Components methods.
   *
   * @type {Object}
   */
  methods: {
    setNewTag({ data }) {
      this.field.tags.push(data._id);
      this.closeAddTag();
    },
    closeAddTag() {
      this.addTagVisible = false;
    },
    async fetchAllGlobalVariableTags() {
      await this.$store.dispatch("globalVariables/fetchGlobalVariableTags");
    },
  },
};
</script>

<style lang="scss" scoped>
.add-field-btn {
  margin-left: 0.5em;
  padding: 0.75em 1em;
  position: relative;
}
</style>