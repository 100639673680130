<template>
  <div>
    <el-form label-position="right">
      <title-and-description :field="field" />

      <el-row type="flex" :gutter="30">
        <el-col :span="12">
          <el-form-item label="Currency Types ">
          <span style="color:red; font-size: 20px;">*</span>
            <el-select
              v-model="field.currency"
              filterable
              default-first-option
              @change="setGlobalVariable"
            >
              <el-option
                v-for="item in CurrencyTypes"
                :key="item.value"
                :label="item.value"
                :value="item.value"
              >
                <span style="float: left">{{ item.value }}</span>
                <span
                  style="
                    float: right;
                    color: var(--el-text-color-secondary);
                    font-size: 13px;
                  "
                  >{{ item.name }}</span
                >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <Value :field="field" />
        </el-col>
        <el-col :span="12">
          <div class="form-group">
            <Tag :field="field" />
          </div>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import TitleAndDescription from "./TitleAndDescription";
import Value from "./Value";
import Tag from "./Tag";

export default {
  components: {
    TitleAndDescription,
    Value,
    Tag,
  },
  props: ["field"],
  data() {
    return {
      validations: [],
      CurrencyTypes: [
        {
          value: "USD",
          name: "$",
        },
        {
          value: "CAD",
          name: "$",
        },
        {
          value: "AUD",
          name: "$",
        },
        {
          value: "INR",
          name: "₹",
        },
        {
          value: "EUR",
          name: "€",
        },
      ],
    };
  },
  methods: {
    setGlobalVariable() {
      if (this.field.currency == "INR") {
        this.field.locale = "en-IN";
      } else if (this.field.currency == "USD") {
        this.field.locale = "en-US";
      } else {
        this.field.locale = "en-IE";
      }
    },
  },
};
</script>

<style lang="scss">
</style>
